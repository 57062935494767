<!-- Contact Dialog -->
<aku-contact-dialog #contactDialog></aku-contact-dialog>

<!-- Icon to open vertical menu when width is not enough to have menu always open -->
@if (!configService.isVerticalMenuAlwaysVisible$()) {
  <div
    class="menu-icon"
    [class.open]="isMenuOpen$()"
    tabindex="-1"
    (keydown.enter)="isMenuOpen$.set(!isMenuOpen$())"
    (click)="isMenuOpen$.set(!isMenuOpen$())">
    <img
      [src]="isMenuOpen$() ? 'assets/menu_icon_close.svg' : 'assets/menu_icon_open.svg'"
      [alt]="isMenuOpen$() ? 'Close menu' : 'Open menu'"
    />
  </div>
}

<div class="vertical-menu-holder"
     [class.not-always-visible]="!configService.isVerticalMenuAlwaysVisible$()"
     [class.open]="isMenuOpen$()"
     [class.closed]="!isMenuOpen$()">
  <!-- Vertical Menu -->
  <div class="menu-options-holder">
    @for (section of menuSections; track $index) {
      @if (section !== null) {
        <div class="menu-option-holder"
             [tabindex]="$index"
             (keydown.enter)="onOptionClick(section.id)"
             (click)="onOptionClick(section.id)"
             [class.contact]="section.id === 'contact'"
             [class.active]="section === activeSection">
          <img [alt]="section.name + ' icon'"
               [src]="environment.bucketUrlImages + section.icon"/>

          <span>{{ section.name }}</span>
        </div>
      } @else {
        <div class="menu-option-empty-holder"></div>
      }
    }
  </div>

  <div class="menu-separator"></div>
</div>