import {Component, ElementRef, Renderer2} from '@angular/core'

@Component({
  selector: 'aku-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public open() {
    // Fade-in animation
    this.renderer.addClass(this.elementRef.nativeElement, 'visible')
  }

  public close() {
    this.renderer.removeClass(this.elementRef.nativeElement, 'visible')
  }
}
