<div #videoHolder class="video-player-holder">
  <aku-overlay></aku-overlay>

  <img #logo class="logo" src="assets/logo.svg" alt="Fomtec logo">

  @for (video of videosToPreLoad; track $index) {
    <video #video
           [id]="video.id"
           [class]="'video' + (configService.activeVideo?.id === video.id ? ' active' : '')"
           controlsList="nodownload nofullscreen noremoteplayback"
           playsinline
    >
      <caption></caption>
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        src=""
        default/>
      <source [src]="environment.bucketUrlVideos + video.url" type="video/mp4">

      Your browser does not support the video tag.
    </video>
  }

  <img
    [class.visible]="showHome"
    class="image"
    alt="Home menu"
    [src]="environment.bucketUrlImages + configService.homeImg">
</div>