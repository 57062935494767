import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core'
import {Subscription} from 'rxjs'
import {environment} from '../../environments/environment'
import {HOR_MENU_WIDTH, LOGO_PERCENTAGE_HEIGHT} from '../application/constants'
import {Video} from '../application/types'
import {ConfigService, NewConfigEvent} from '../services/config.service'

@Component({
  selector: 'aku-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  protected readonly environment = environment

  /**
   * Videos that will be set in the template to be preloaded. This includes all
   * vides that have been reproduced too, meaning that once they are included
   * here, they never get removed. It is like a cache (sort of).
   * More videos will be added with every overlay change (OverlayItems have
   * "nextVideoId" which will make videos to preload).
   */
  public videosToPreLoad: Video[] = []

  /**
   * Element (<div>) that contains the video player. Overlay depends on these
   * dimensions to resize its items.
   */
  @ViewChild('videoHolder', {static: true}) public videoHolderRef!: ElementRef
  /**
   * Element (<img>) that contains company logo. It should resize with screen
   */
  @ViewChild('logo', {static: true}) public logoRef!: ElementRef
  /**
   * Video element that we use to reproduce videos.
   */
  @ViewChildren('video') public preLoadedVideos!: QueryList<ElementRef<HTMLVideoElement>>
  /**
   * Flag to know if the home image should be shown or not
   */
  public showHome: boolean = true

  // Subscriptions to remove and not leak memory
  private subs$: Subscription[] = []

  constructor(
    private renderer: Renderer2,
    public configService: ConfigService
  ) {
  }

  public ngAfterViewInit() {
    // Do an initial change dimensions event
    this.onResize(window)

    this.subs$.push(
      this.configService.actions$
        .subscribe(actions => {
          actions.forEach(action => {
            switch (action) {
              case 'hide_home':
                this.showHome = false
                break
              case 'show_home':
                this.showHome = true
                break
              case 'preload_videos':
                // Preload videos from overlay items
                this.preLoadVideos()
                break
              case 'setup_video':
                // Setup video
                this.setUpNewVideo(this.configService.activeVideo as Video)
                break
              case 'setup_and_play_video':
                // Setup video and start playing it
                this.setUpNewVideo(this.configService.activeVideo as Video)
                  .play().then()
                break
              case 'reset_videos':
                // Reset all videos that config service indicate
                this.configService.videosToReset.forEach(video => {
                  this.preLoadedVideos
                    .map(ref => ref.nativeElement)
                    .filter(el => el.id === video.id)
                    .forEach(el => {
                      el.pause()
                      el.currentTime = 0
                    })
                })
                break
            }
          })
        })
    )
  }

  public ngOnDestroy() {
    this.subs$.forEach(s => s.unsubscribe())
  }

  @HostListener('window:resize', ['$event.currentTarget'])
  private onResize(window: Window) {
    // Take into consideration the vertical menu (if always visible, meaning
    // that is not floating on top of everything
    this.changeDimensions(
      window.innerWidth -
      (this.configService.isVerticalMenuAlwaysVisible$() ? HOR_MENU_WIDTH : 0),
      window.innerHeight
    )
  }

  private preLoadVideos() {
    this.configService.videosToPreLoad.forEach(video => {
      // Add video to "videosToPreLoad" if not already present
      if (!this.videosToPreLoad.some(v => v.id === video.id)) {
        // Use a little timeout to avoid errors when initial loading it
        // like "ExpressionChangedAfterItHasBeenCheckedError".
        // At the end of the day, this is triggering a template change.
        setTimeout(() => {
          this.videosToPreLoad.push(video)
        }, 1)
      }
    })
  }

  private setUpNewVideo(video: Video): HTMLVideoElement {
    // Set active video player
    const videoElement: HTMLVideoElement = this.preLoadedVideos
      .find(el => el.nativeElement.id === video.id)!
      .nativeElement

    // Send config event when video ends
    videoElement.onended = () => {
      this.configService.sendEvent(NewConfigEvent.VideoEnd(video.onEndActions))
    }

    // Controls appearing method will change from touchable screens and
    // desktop screens.
    if (this.configService.isTouchableScreen()) {
      // Controls are always visible if it is a touchable device
      this.renderer.setAttribute(videoElement, 'controls', '')
    } else {
      // Show controls when hovering and hide them when not
      videoElement.onmouseenter = () => {
        this.renderer.setAttribute(videoElement, 'controls', '')
      }
      videoElement.onmouseleave = () => {
        this.renderer.removeAttribute(videoElement, 'controls')
      }
    }

    return videoElement
  }

  /**
   * It calculates the new dimension that our "videoHolder" (container element)
   * should have after a resize event or after initialisation. Always maintaining
   * the original dimension's ratio.
   * It receives the new window dimensions, and with the configuration loaded,
   * we calculate new possible dimensions for "videoHolder" using the
   * configuration dimension's ratio.
   * We need to test two possible solutions: (A) New size will make the video
   * player's container overflow vertically. (B) New size will make the video
   * player's container overflow horizontally.
   * A.- Calculating container's new width (newX) using the new window's height
   * (newHeight) we see that it fits inside the new window's width (newWidth).
   * In that case, we calculate the new container's height (newY) using the
   * container's ratio.
   * B.- After calculating newX it doesn't fit inside newWidth. Then we will need
   * to calculate container's new height (newY) using the new window's width
   * (newWidth) and then calculate newX using container's ratio.
   * Remember that "Width = Height * ratio" & "Height = Width / ratio"
   * @param newWidth New window screen's width
   * @param newHeight New window screen's height
   * @private
   */
  private changeDimensions(newWidth: number, newHeight: number) {
    // Calculate new possible container's width using new window's height
    let newX: number = newHeight * this.configService.dimensions.ratio
    let newY: number
    if (newX <= newWidth) {
      // Container's new width fits inside the new window's width.
      // Meaning that "newY" is the same as new window's height.
      newY = newHeight
    } else {
      // Container's new width doesn't fit inside the new window's width.
      // We calculate new container's height using new window's width,
      // "newX" is the same as new window's width.
      newY = newWidth / this.configService.dimensions.ratio
      newX = newWidth
    }

    // Set new dimensions to container element "videoHolder"
    this.renderer.setStyle(this.videoHolderRef.nativeElement, 'width', `${newX}px`)
    this.renderer.setStyle(this.videoHolderRef.nativeElement, 'height', `${newY}px`)

    // Set logo dimension depending on "videoHolder" new values
    this.renderer.setStyle(this.logoRef.nativeElement, 'height', `${newY * LOGO_PERCENTAGE_HEIGHT}px`)

    // Send a new global event so listening components can resize accordingly
    this.configService.setVideoPlayerSize(newX, newY)
  }
}
