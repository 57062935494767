import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {HOME_ROUTE} from './application/constants'
import {VideoPlayerComponent} from './video-player/video-player.component'

const routes: Routes = [
  {
    path: HOME_ROUTE,
    component: VideoPlayerComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: HOME_ROUTE
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
