@for (item of currentOverlay; track $index) {
  <div #overlayItem [id]="item.id" class="overlay-item">
    <img class="overlay-item-image"
         [src]="environment.bucketUrlImages + item.img"
         alt="poi">

    @if (item.title) {
      <span class="overlay-item-title">{{ item.title.text }}</span>
    }
  </div>
}