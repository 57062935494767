import {NgOptimizedImage} from '@angular/common'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {VideoDemoConfiguration} from './application/configuration'
import {OverlayComponent} from './overlay/overlay.component'
import {ConfigService} from './services/config.service'
import {
  ContactDialogComponent
} from './vertical-menu/contact-dialog/contact-dialog.component'
import {VerticalMenuComponent} from './vertical-menu/vertical-menu.component'
import {VideoPlayerComponent} from './video-player/video-player.component'

@NgModule({
  declarations: [
    AppComponent,
    VideoPlayerComponent,
    OverlayComponent,
    VerticalMenuComponent,
    ContactDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.setConfiguration(VideoDemoConfiguration),
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
