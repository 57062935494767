/**
 * App routes
 */
export const HOME_ROUTE = ''

/**
 * Random variables used in the app
 */
// If this value is modified, all positions will change
export const OVERLAY_ITEM_PERCENTAGE_SIZE: number = 0.07
export const OVERLAY_ITEM_TITLE_TEXT_RATIO: number = 0.26
export const OVERLAY_ITEM_TITLE_V_PADDING_RATIO: number = 0.06
export const OVERLAY_ITEM_TITLE_H_PADDING_RATIO: number = 0.19
export const LOGO_PERCENTAGE_HEIGHT: number = 0.06
// Make sure to modify $menu-width in vertical-menu.scss too
export const HOR_MENU_WIDTH: number = 200