import {Injectable, signal} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {
  Configuration,
  Dimensions,
  OnEndActions,
  Overlay,
  Section,
  Video
} from '../application/types'

type TConfigEvent = 'video_end' | 'overlay_click' | 'section_click'

class ConfigEvent {
  type: TConfigEvent

  constructor(type: TConfigEvent) {
    this.type = type
  }
}

class VideoEndEvent extends ConfigEvent {
  onEndActions: OnEndActions

  constructor(onEndActions: OnEndActions) {
    super('video_end')
    this.onEndActions = onEndActions
  }
}

class OverlayClickEvent extends ConfigEvent {
  videoId: string

  constructor(videoId: string) {
    super('overlay_click')
    this.videoId = videoId
  }
}

class SectionClickEvent extends ConfigEvent {
  section: string

  constructor(section: string) {
    super('section_click')
    this.section = section
  }
}

export const NewConfigEvent = {
  VideoEnd: (onEndActions: OnEndActions) => new VideoEndEvent(onEndActions),
  OverlayClick: (videoId: string) => new OverlayClickEvent(videoId),
  SectionClick: (section: string) => new SectionClickEvent(section)
}

export type TConfigAction =
  'setup_overlay' // overlay_click or section_click
  | 'preload_videos' // overlay_click or section_click (home)
  | 'setup_video' // section_click (no-home & w/o "autoplay")
  | 'setup_and_play_video' // overlay_click or section_click (no-home & w/ "autoplay")
  | 'reset_videos' // section_click
  | 'show_menu_options' // video_end (w/ "openOverlay") or section_click
  | 'close_menu' // overlay_click or section_click
  | 'show_overlay' // video_end (w/ "openOverlay"), section_click (home) or section_click (no-home & w/o "autoplay")
  | 'hide_overlay' // overlay_click or section_click (no-home & w/ "autoplay")
  | 'show_home' // section_click (home)
  | 'hide_home' // overlay_click or section_click (no-home)

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public isVerticalMenuAlwaysVisible$ = signal<boolean>(false)

  /**
   * Observable that will receive all the actions that have been triggered by
   * "sendEvent". Actions come in arrays so that a new subscribing item can
   * receive a "copy" of the last actions performed, so it can do them too.
   */
  public actions$: Observable<TConfigAction[]>
  private pActions$: BehaviorSubject<TConfigAction[]> =
    new BehaviorSubject<TConfigAction[]>([])

  /**
   * Observable to use when a component needs to update its dimension because
   * the user has resized the browser's window
   */
  public videoPlayerDimensions$: Observable<Dimensions>
  private pVideoPlayerDimensions$: BehaviorSubject<Dimensions> =
    new BehaviorSubject<Dimensions>(new Dimensions(0, 0))

  /**
   * Configuration of the Video Demo. It should never be used directly in any
   * Component, all is access via getters to protect from further modifications.
   * @private
   */
  private configuration!: Configuration
  /**
   * Current running video, which can be a video or null, meaning that user is
   * idle at "home".
   * @private
   */
  private pActiveVideo: Video | null = null

  constructor() {
    this.actions$ = this.pActions$.asObservable()
    this.videoPlayerDimensions$ = this.pVideoPlayerDimensions$.asObservable()

    this.isVerticalMenuAlwaysVisible$.set(!this.isTouchableScreen())
  }

  /////////////////////////////////////////////////
  // Aux methods
  /////////////////////////////////////////////////
  public isTouchableScreen(): boolean {
    return window.matchMedia('(pointer: coarse)').matches
  }

  public setVideoPlayerSize(width: number, height: number) {
    this.pVideoPlayerDimensions$.next(new Dimensions(width, height))
  }

  /////////////////////////////////////////////////
  // End of Aux Methods
  /////////////////////////////////////////////////


  /////////////////////////////////////////////////
  // Configuration
  /////////////////////////////////////////////////
  /**
   * This method should be executed as APP_INITIALIZER in app.modules.ts.
   * The reason of this is that the whole project depends on the configuration.
   * It makes no sense to wait for it's loading later. It's needed at the start.
   * @param configuration Configuration to use in the project
   */
  public setConfiguration(configuration: Configuration) {
    this.configuration = configuration
    // Simulate a click in the first section, home section to start rendering
    this.sendEvent(NewConfigEvent.SectionClick(this.configuration.homeSection.id))
  }

  /////////////////////////////////////////////////
  // End of Configuration
  /////////////////////////////////////////////////


  /////////////////////////////////////////////////
  // Events
  /////////////////////////////////////////////////
  /**
   * Receives events to execute actions with them. Every component should use
   * this to perform any action instead of managing configuration, videos or
   * sections themselves. By doing this we manage a very flexible "app shell"
   * and it can be reused for future cases.
   * All works "similarly" to a state machine: receive an event, emit actions.
   */
  public sendEvent(configEvent: ConfigEvent) {
    let event
    switch (configEvent.type) {
      case 'video_end':
        event = configEvent as VideoEndEvent
        // Depending on the "OnEndActions" the behaviour will be different
        if (event.onEndActions.goToSection) {
          // Simulate section click event with the "goToSection"
          this.sendEvent(NewConfigEvent.SectionClick(event.onEndActions.goToSection))
        } else {
          this.pActions$.next(['preload_videos', 'show_overlay', 'show_menu_options'])
        }
        break
      case 'overlay_click':
        event = configEvent as OverlayClickEvent
        // Actions
        this.setActiveVideoById(event.videoId)
        this.pActions$.next(['close_menu', 'hide_home', 'hide_overlay', 'setup_and_play_video', 'setup_overlay'])
        break
      case 'section_click':
        event = configEvent as SectionClickEvent
        // Avoid multi-click if we are already in the section and showing the
        // overlay, meaning that we are idle in the overlay and clicking menu.
        if (this.isOverlayOpen() && this.activeVideoSection.id === event.section) {
          return
        }

        // If section is home it will be a different behaviour. It is a reset.
        if (event.section === this.configuration.homeSection.id) {
          // Pre-actions -> Show home needs to be before any reset video or
          // show overlay, that's why we send an action before doing so.
          this.pActions$.next(['show_home'])
          // Actions
          this.pActiveVideo = null
          this.pActions$.next(['close_menu', 'preload_videos', 'setup_overlay', 'show_overlay', 'show_menu_options', 'reset_videos'])
        } else {
          // Pre-actions
          this.pActions$.next(['reset_videos'])
          // Actions
          this.setActiveVideoBySection(event.section)
          if (this.activeVideoSection.autoplay) {
            this.pActions$.next(['hide_home', 'hide_overlay', 'show_menu_options', 'setup_and_play_video', 'setup_overlay'])
          } else {
            this.pActions$.next(['hide_home', 'show_overlay', 'show_menu_options', 'setup_video', 'setup_overlay'])
          }
        }
        break
    }
  }

  private isOverlayOpen(): boolean {
    return this.pActions$.value.includes('show_overlay')
  }

  private setActiveVideoById(videoId: string) {
    this.pActiveVideo = this.configuration.videos
      .find(v => v.id === videoId)!
  }

  private setActiveVideoBySection(section: string) {
    // Get first video that has the given section
    this.pActiveVideo = this.configuration.videos
      .find(v => v.section === section)!
  }

  /////////////////////////////////////////////////
  // End of Events
  /////////////////////////////////////////////////


  /////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////

  /**
   * Returns Dimensions defined in configuration
   */
  public get dimensions(): Dimensions {
    return this.configuration.dimensions
  }

  /**
   * Returns home image URL defined in configuration
   */
  public get homeImg(): string {
    return this.configuration.homeImg
  }

  /**
   * Returns all videos defined in configuration
   */
  public get videos(): Video[] {
    return this.configuration.videos
  }

  /**
   * Returns all sections defined in configuration
   */
  public get sections(): Section[] {
    return this.configuration.sections
  }

  /**
   * Returns active video if there is a video running. Null otherwise.
   */
  public get activeVideo(): Video | null {
    return this.pActiveVideo
  }

  /**
   * Returns active video's section if there is a video running. Home section
   * otherwise.
   */
  public get activeVideoSection(): Section {
    return this.configuration.sections
        .find(s => s.id === this.activeVideo?.section) ??
      this.configuration.homeSection
  }

  /**
   * Returns active overlay, which is the current video's overlay.
   * If there is no video running, configuration's initial overlay.
   */
  public get activeOverlay(): Overlay {
    return this.activeVideo?.overlay ??
      this.configuration.initialOverlay
  }

  /**
   * Returns active sections, which are the current video's section's visible
   * sections. If there is no video running, home section's visible sections.
   */
  public get activeSections(): Section[] {
    return this.activeVideoSection.visibleSections.map(id =>
      this.configuration.sections.find(s => s.id === id)!)
  }

  /**
   * Returns all videos that should be preloaded, which are all those contained
   * in the active video's overlay and all first videos in the visible sections
   * with "autoplay" flag.
   */
  public get videosToPreLoad(): Video[] {
    const overlayVideos: Video[] = this.activeOverlay.items
      .map(i => this.videos.find(v => v.id === i.videoId))
      .filter(Boolean) as Video[]
    const sectionVideos: Video[] = this.activeSections
      .filter(s => s.autoplay)
      .map(s => this.videos.find(v => v.section === s.id))
      .filter(Boolean) as Video[]

    // Avoid repeated videos
    return Array.from(new Set(overlayVideos.concat(sectionVideos)))
  }

  /**
   * Returns all the videos that should be reset with action "reset_videos",
   * which are all the videos "in a lower level section than active section",
   * and the videos targeted by the active overlay.
   */
  public get videosToReset(): Video[] {
    // Get index of active section
    const activeSectionIndex = this.sections
      .findIndex(s => s === this.activeVideoSection)
    // Get all "lower-level" sections and active one
    const sections = this.sections.slice(activeSectionIndex)
    // Find all videos contained in those selected sections
    const videos = sections
      .flatMap(section =>
        this.videos.filter(v => v.section === section.id))


    // Exclude active section's "intro-section-video" (first one in the section)
    const index = videos.findIndex(v =>
      v === this.getSectionOverlayVideo(this.activeVideoSection.id))
    if (index !== -1) {
      videos.splice(index, 1)
    }

    return videos
  }

  /////////////////////////////////////////////////
  // End of Getters
  /////////////////////////////////////////////////

  /**
   * Section overlay video is that video that when finished, will stay at its
   * last frame as image to draw overlay over.
   * The qualifiers to be that video are:
   *  - First video of the section
   *  - Video has at least one OverlayItem in its overlay
   * @param section Section ID
   * @private
   */
  private getSectionOverlayVideo(section: string): Video | undefined {
    // Section overlay video is that video
    return this.videos
      .filter(v => v.overlay.items.length > 0)
      .find(v => v.section === section)
  }
}
