import {Component, OnInit, signal, ViewChild} from '@angular/core'
import {environment} from '../../environments/environment'
import {Section} from '../application/types'
import {ConfigService, NewConfigEvent} from '../services/config.service'
import {ContactDialogComponent} from './contact-dialog/contact-dialog.component'

@Component({
  selector: 'aku-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {
  protected readonly environment = environment

  /**
   * Menu sections available. The reason that they can be null is that the menu
   * should always take the same height, so null sections will print an empty
   * div with same dimensions as normal Sections.
   */
  public menuSections: (Section | null)[] = []
  /**
   * We use a local flag to get active section instead of using config service's
   * one because it will change before the video is finished if not.
   */
  public activeSection: Section | null = null
  /**
   * Flag used when menu is not always visible to handle when the menu is open
   * by click the "hamburger" icon or closed.
   */
  public isMenuOpen$ = signal(false)

  @ViewChild('contactDialog', {static: true}) contactDialog!: ContactDialogComponent

  constructor(protected configService: ConfigService) {
  }

  public ngOnInit() {
    this.configService.actions$
      .subscribe(actions => {
        actions.forEach(action => {
          // Vertical Menu only cares about "show_menu_options" and
          // "close_menu" actions
          if (action === 'show_menu_options') {
            this.showMenuOptions()
          }
          if (action === 'close_menu') {
            this.isMenuOpen$.set(false)
          }
        })
      })
  }

  private showMenuOptions() {
    // Clear previous menu options & close menu
    this.menuSections = []

    // Add new menu options (always maintaining the same number of options, 4)
    this.configService.sections
      .filter(s => this.configService.activeSections.includes(s))
      .forEach(section => {
        this.menuSections.push(section)
      })
    // We always assume that last section is "contact"
    for (let i = this.menuSections.length; i < 4; i++) {
      this.menuSections.splice(this.menuSections.length - 1, 0, null)
    }

    // Set active section
    this.activeSection = this.configService.activeVideoSection
  }

  public onOptionClick(section: string) {
    // Special case for "contact" section, which will display a dialog
    if (section === 'contact') {
      this.showContactDialog()
      return
    }
    // Send ConfigEvent
    this.configService.sendEvent(NewConfigEvent.SectionClick(section))
  }

  private showContactDialog() {
    this.contactDialog.open()
  }
}
