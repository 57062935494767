<div class="contact-dialog-holder">
  <button class="close-button" (click)="close()">X</button>

  <div class="info-row">
    <img src="assets/icon_mail.svg" alt="mail">
    <span>{{ 'contactemail@fomtec.se' }}</span>
  </div>

  <div class="info-row">
    <img src="assets/icon_phone.svg" alt="phone">
    <span>+46 111222333</span>
  </div>
</div>
