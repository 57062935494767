import {
  Configuration,
  Dimensions,
  OnEndActions,
  Overlay,
  OverlayItem,
  Section,
  Title,
  Video
} from './types'

export const VideoDemoConfiguration = new Configuration(
  new Dimensions(2000, 2000),
  'menu_home.png',
  new Overlay([
    new OverlayItem('airport', 63, 51, 'poi_airport.png', 'aviation_landing'),
    new OverlayItem('harbor', 62, 79, 'poi_harbor.png'),
    new OverlayItem('loading_truck', 90.2, 64.5, 'poi_loading_truck.png', 'truck_loading')
  ]),
  [
    new Video(
      'truck_loading',
      '1_Truck_Loading_intro.mp4',
      new Overlay(
        [
          new OverlayItem('foam', 11, 70, 'poi_foam.png', 'truck_loading_foam', new Title('Foam', 'top')),
          new OverlayItem('sprinkler', 34, 67, 'poi_sprinkler.png', 'truck_loading_sprinkler', new Title('Sprinkler', 'top'))
        ]
      ),
      'truck_loading',
      OnEndActions.openOverlay()
    ),
    new Video(
      'guidelines',
      '2_Truck_Loading_NFPA_11.mp4',
      new Overlay(),
      'guidelines',
      OnEndActions.goToSection('truck_loading')
    ),
    new Video(
      'truck_loading_foam',
      '3_Truck_Loading_Options.mp4',
      new Overlay(),
      'truck_loading',
      OnEndActions.goToSection('truck_loading')
    ),
    new Video(
      'truck_loading_sprinkler',
      '5_Truck_Loading_discharge.mp4',
      new Overlay(),
      'truck_loading',
      OnEndActions.goToSection('business_areas')
    ),
    new Video(
      'aviation_landing',
      '1_Aviation_landing.mp4',
      new Overlay(),
      'aviation_landing',
      OnEndActions.openOverlay()
    )
  ],
  [
    new Section(
      'business_areas',
      'Business Areas',
      'icon_map.png',
      ['business_areas', 'contact']
    ),
    new Section(
      'truck_loading',
      'Truck Loading Overview',
      'icon_loading_truck.png',
      ['business_areas', 'truck_loading', 'guidelines', 'contact']
    ),
    new Section(
      'guidelines',
      'Guidelines & Regulations',
      'icon_regulations.png',
      ['business_areas', 'truck_loading', 'guidelines', 'contact'],
      true
    ),
    new Section(
      'aviation_landing',
      'Aviation Landing',
      'icon_aviation_plane.png',
      ['business_areas', 'aviation_landing', 'contact']
    ),
    new Section(
      'contact',
      'Contact',
      'icon_contact.png',
      ['business_areas', 'contact']
    )
  ]
)