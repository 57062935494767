export class Configuration {
  dimensions: Dimensions
  homeImg: string
  initialOverlay: Overlay
  videos: Video[]
  sections: Section[]

  constructor(
    dimensions: Dimensions,
    homeImg: string,
    initialOverlay: Overlay,
    videos: Video[],
    sections: Section[]
  ) {
    this.dimensions = dimensions
    this.homeImg = homeImg
    this.initialOverlay = initialOverlay
    this.videos = videos
    this.sections = sections
  }

  get homeSection(): Section {
    return this.sections[0]
  }
}

export class Section {
  id: string
  name: string
  icon: string
  visibleSections: string[]
  autoplay: boolean

  constructor(
    id: string,
    name: string,
    icon: string,
    visibleSections: string[] = [],
    autoplay: boolean = false
  ) {
    this.id = id
    this.name = name
    this.icon = icon
    this.visibleSections = visibleSections
    this.autoplay = autoplay
  }
}

export class OnEndActions {
  goToSection: string | null
  openOverlay: boolean

  constructor(goToSection: string | null, openOverlay: boolean) {
    this.goToSection = goToSection
    this.openOverlay = openOverlay
  }


  public static goToSection(section: string): OnEndActions {
    return new OnEndActions(section, false)
  }

  public static openOverlay(): OnEndActions {
    return new OnEndActions(null, true)
  }
}

export class Video {
  id: string
  url: string
  overlay: Overlay
  section: string
  onEndActions: OnEndActions

  constructor(
    id: string,
    url: string,
    overlay: Overlay,
    section: string,
    onEndActions: OnEndActions
  ) {
    this.id = id
    this.url = url
    this.overlay = overlay
    this.section = section
    this.onEndActions = onEndActions
  }
}

export class Overlay {
  items: OverlayItem[] = []

  constructor(items: OverlayItem[] = []) {
    this.items = items
  }
}

export class OverlayItem {
  id: string
  posX: number
  posY: number
  img: string
  videoId: string | null
  title?: Title
  dimensions?: Dimensions

  constructor(
    id: string,
    posX: number,
    posY: number,
    img: string,
    videoId: string | null = null,
    title?: Title
  ) {
    this.id = id
    this.posX = posX
    this.posY = posY
    this.img = img
    this.videoId = videoId
    this.title = title
  }
}

export type TAnchor = 'top' | 'right'

export class Title {
  text: string
  anchor: TAnchor
  fontSize: number = 0

  constructor(title: string, anchor: TAnchor) {
    this.text = title
    this.anchor = anchor
  }
}

export class Dimensions {
  ratio: number
  width: number
  height: number

  constructor(width: number, height: number) {
    this.width = width
    this.height = height
    this.ratio = width / height
  }
}