import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core'
import {filter, Subscription} from 'rxjs'
import {environment} from '../../environments/environment'
import {
  OVERLAY_ITEM_PERCENTAGE_SIZE,
  OVERLAY_ITEM_TITLE_H_PADDING_RATIO,
  OVERLAY_ITEM_TITLE_TEXT_RATIO,
  OVERLAY_ITEM_TITLE_V_PADDING_RATIO
} from '../application/constants'
import {Dimensions, OverlayItem} from '../application/types'
import {ConfigService, NewConfigEvent} from '../services/config.service'

export class OverlayItemHolder {
  element: HTMLDivElement

  constructor(ref: ElementRef<HTMLDivElement>) {
    this.element = ref.nativeElement
  }

  get image(): HTMLImageElement {
    return this.element.firstChild as HTMLImageElement
  }

  get title(): HTMLSpanElement | null {
    // The title element is the second child whenever there are 3 children.
    // This is because there is always a last element called "comment".
    return this.element.childNodes.length > 2 ?
      this.element.childNodes.item(1) as HTMLSpanElement :
      null
  }
}

@Component({
  selector: 'aku-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements AfterViewInit, OnDestroy {
  protected readonly environment = environment

  /**
   * Overlay items size in relation with the Video Player (parent component)
   * size. For example, items are adapted to be a 1% of the video player's size.
   */
  private overlayItemSize: number = 0
  /**
   * Overlay to be displayed. It can change and it will need to be re-rendered
   */
  public currentOverlay: OverlayItem[] = []
  /**
   * HTML elements that represent every OverlayItem appended to the DOM. They
   * are linked to an OverlayItem via ID attribute.
   */
  @ViewChildren('overlayItem') public overlayItems!: QueryList<ElementRef<HTMLDivElement>>

  private get overlayItemHolders(): OverlayItemHolder[] {
    return this.overlayItems
      .map(oI => new OverlayItemHolder(oI))
  }

  // Subscriptions to remove and not leak memory
  private subs$: Subscription[] = []

  constructor(
    protected configService: ConfigService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public ngOnDestroy() {
    this.subs$.forEach(s => s.unsubscribe())
  }

  public ngAfterViewInit() {
    this.overlayItems.changes.subscribe(() => this.renderOverlay())

    this.subs$.push(
      this.configService.actions$
        .subscribe(actions => {
          actions.forEach(action => {
            switch (action) {
              case 'setup_overlay':
                // Reset current overlay to always trigger a new render.
                // There are some problem when using hor-menu if not.
                this.currentOverlay = []

                // Use a little timeout to avoid errors when initial loading it
                // like "ExpressionChangedAfterItHasBeenCheckedError".
                // At the end of the day, this is triggering a template change.
                setTimeout(() => {
                  // Save current overlay and render it
                  this.currentOverlay = this.configService.activeOverlay.items
                }, 1)
                break
              case 'show_overlay':
                // Remove previous "hidden" class and add "visible", which will
                // make it visible (dah) and also animating the opacity
                this.renderer.removeClass(this.elementRef.nativeElement, 'hidden')
                this.renderer.addClass(this.elementRef.nativeElement, 'visible')
                break
              case 'hide_overlay':
                // Add "hidden"", which will make it hidden (dah, again), but
                // also maintaining the opacity to 0, so when going back to
                // "visible" it can do the fade-in animation.
                this.renderer.addClass(this.elementRef.nativeElement, 'hidden')
                break
            }
          })
        })
    )

    // Subscribe to Video Player resizes to re-calculate Overlay items size
    this.subs$.push(
      this.configService.videoPlayerDimensions$
        .pipe(filter(value => value.width > 0 && value.height > 0))
        .subscribe(value => {
          // Calculate the new item size with the new Video Player dimensions
          this.overlayItemSize = Math.min(value.width, value.height) * OVERLAY_ITEM_PERCENTAGE_SIZE

          // Resize all elements in the overlay
          this.resizeCurrentOverlay()
        })
    )
  }

  /**
   * It renders the "currentOverlay", adding functionality to all OverlayItems
   * @private
   */
  private renderOverlay() {
    this.overlayItemHolders.forEach((holder) => {
      // Item associated to HTML image element
      const item = this.currentOverlay.find(i => i.id === holder.element.id)!

      // Add onLoad functionality to the image element
      const img = holder.image
      img.onload = () => {
        // Set OverlayItem dimensions from the loaded <img>
        item.dimensions = new Dimensions(img.width, img.height)

        // Resize element once is loaded
        this.resizeOverlayItem(item, holder)
      }
      // Sometimes onload will not be triggered if overlay was open when it
      // changed. For those cases, we call resize here too.
      // It will only work on those specific cases, otherwise, because item
      // has no dimensions, it will do nothing.
      this.resizeOverlayItem(item, holder)

      // Add onClick functionality to image element
      img.onclick = () => {
        // Disable click instantly to avoid double-clicking!
        img.onclick = null

        // Do a click animation on POI
        this.renderer.addClass(holder.element, 'click-animation')

        // Fade-out overlay, close it and send config event after animation ends
        this.renderer.addClass(this.elementRef.nativeElement, 'selection-animation')
        setTimeout(() => {
          // Send ConfigEvent
          // Ideally all POIs will have a video associated, for now let's check
          if (item.videoId) {
            this.configService.sendEvent(NewConfigEvent.OverlayClick(item.videoId))
          }

          // Remove animation class
          this.renderer.removeClass(this.elementRef.nativeElement, 'selection-animation')
        }, 1000)
      }
    })
  }

  /**
   * Resizes all the overlay items contained in the "currentOverlay"
   * @private
   */
  private resizeCurrentOverlay() {
    this.currentOverlay.forEach((item: OverlayItem) => {
      const holder = this.overlayItemHolders
        .find(h => h.element.id === item.id)
      if (holder) {
        this.resizeOverlayItem(item, holder)
      }
    })
  }

  /**
   * We set the new size to the smallest dimension (width or height), and then
   * we apply the item's ratio to the other dimension. E.g. A taller than
   * wider item, like a phone, will set its new width to "overlayItemSize" and
   * it will set its new height to "overlayItemSize / ratio".
   * Remember that "Width = Height * ratio" & "Height = Width / ratio"
   * @param item OverlayItem to resize
   * @param holder Elements group associated to Overlay Item
   * @private
   */
  private resizeOverlayItem(item: OverlayItem, holder: OverlayItemHolder) {
    if (item.dimensions) {
      let newWidth: number
      let newHeight: number

      if (item.dimensions.ratio >= 1) {
        // Items that are wider than taller, computer-screen-shape items
        newWidth = this.overlayItemSize * item.dimensions.ratio
        newHeight = this.overlayItemSize
      } else {
        // Items that are taller than wider, phone-screen-shape items
        newWidth = this.overlayItemSize
        newHeight = this.overlayItemSize / item.dimensions.ratio
      }

      // Set new dimensions to image element
      this.renderer.setStyle(holder.image, 'width', `${newWidth}px`)
      this.renderer.setStyle(holder.image, 'height', `${newHeight}px`)

      // Set new dimensions to title element
      if (holder.title) {
        this.renderer.setStyle(holder.title, 'fontSize',
          `${newWidth * OVERLAY_ITEM_TITLE_TEXT_RATIO}px`)
        this.renderer.setStyle(holder.title, 'lineHeight',
          `${newWidth * OVERLAY_ITEM_TITLE_TEXT_RATIO}px`)
        this.renderer.setStyle(holder.title, 'padding',
          `${newWidth * OVERLAY_ITEM_TITLE_V_PADDING_RATIO}px ${newWidth * OVERLAY_ITEM_TITLE_H_PADDING_RATIO}px`)
      }

      // Re-position main element according to new dimensions
      this.renderer.setStyle(holder.element, 'left', `calc(${item.posX}% - ${newWidth / 2}px)`)
      this.renderer.setStyle(holder.element, 'top', `calc(${item.posY}% - ${newHeight / 2}px)`)
    }
  }
}
